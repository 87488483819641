<template>
  <div v-html="setHtml"></div>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores/storeGlobal.js'
import { useCustomerStore } from '#root/stores/storeCustomer.js'
import { useGetContentPage } from '#root/components/composables/getContent'
import { capitalizeFirstLetter } from '#root/utils/helpers/helperData.js'

const globalStore = useGlobalStore()
const customerStore = useCustomerStore()
const { dynamicPhoneNumber } = storeToRefs(globalStore)
const { customer } = storeToRefs(customerStore)

const props = defineProps({
  content: { type: String, required: true, default: '' },
  gtm: { type: String, required: false, default: '' },
  nofollow: { type: Boolean, required: false, default: false }
})

const setHtml = computed(() => {
  if (!props.content) return false
  const foundTags = props.content.match(/<a[^>]*>(.*?)<\/a>/g)
  const foundHeadingTag = props.content.match(/<h[1-6][^>]*>(.*?)<\/h[1-6]>/g)
  let template = props.content
  if (foundTags) {
    for (let t = 0; t < foundTags.length; t++) {
      const tag = foundTags[t]
      if (props.nofollow) {
        let newTag = tag.replace('rel="noopener noreferrer"', 'rel="nofollow noopener noreferrer"')
        template = template.replace(tag, newTag)
      }
      if (
        !tag.includes('tel:') &&
        !tag.includes('mailto:') &&
        !tag.includes('target=') &&
        !tag.includes('href="http') &&
        !tag.includes("href='http") &&
        !tag.includes('href="javascript:')
      ) {
        let newTag = tag.replace(/<a href=/g, `<a data-gtm="${props.gtm}" href=`)
        newTag = newTag.replace(/<\/a>/g, '</a>')
        template = template.replace(tag, newTag)
      }
      if (tag.includes('://tel:')) {
        const newTag = tag.replace(/http:\/\/tel:/g, 'tel:')
        template = template.replace(tag, newTag)
      }
      if (tag.includes('://javascript:')) {
        let newTag = tag.replace(/http:\/\/javascript:/g, 'javascript:')
        newTag = newTag.replace(/<a/g, `<a data-gtm="${props.gtm}"`)
        template = template.replace(tag, newTag)
      }
    }
  }

  if (foundHeadingTag) {
    for (let t = 0; t < foundHeadingTag.length; t++) {
      const tag = foundHeadingTag[t]
      if (!tag.includes('id=')) {
        const headingContent = tag
          .replace(/<h[1-6][^>]*>/g, '')
          .replace(/<\/h[1-6]>/g, '')
          .replace(/[!?]/g, '')
        const newTag = tag.replace(
          /<h[1-6]/g,
          `<h${tag.charAt(2)} id="${headingContent.replace(/ /g, '_').toLowerCase()}"`
        )
        template = template.replace(tag, newTag)
      }
    }
  }

  const regexpId = /\[\[id=(.*?)\]\]/gim // Search for [[id=XXX]] from Kentico Content
  const regexpTitle = /\[\[title=(.*?)\]\]/gim // Search for [[title=XXX]] from Kentico Content
  const regexSquareBrackets = /\[\[(.*?)\]\]/g

  const replaceText = (match, string) => {
    const content = template.match(regexSquareBrackets)[0].replace(/\[\[(.*?)\]\]/g, '$1')
    const splitContent = content?.split('/')

    if (content && template.includes('MODAL_CALLBACK')) {
      const defaultContent = 'click here to set your own callback'
      return `<span role="button" class="toggle-callback-modal">${
        splitContent[1] ? splitContent[1] : defaultContent
      }</span>`
    } else if (content && template.includes('OPEN_ONETRUST')) {
      const defaultContent = 'here'
      return `<span role="button" class="optanon-toggle-display" onclick="Optanon.ToggleInfoDisplay();">${
        splitContent[1] ? splitContent[1] : defaultContent
      }</span>`
    } else {
      return ''
    }
  }

  let renderedHtml = template
    .replaceAll(
      '[[PHONENUMBER]]',
      `<a href="tel:${dynamicPhoneNumber.value}" class="cta-phone">${dynamicPhoneNumber.value}</a>`
    )
    .replace(/\{\{(?:USERNAME|GREETINGNAME)\}\}/g, capitalizeFirstLetter(customer.value?.firstName) ?? '')
    .replace(
      regexpId,
      (match, string) =>
        `<span id="${string}" data-id="${string}" class="article-heading-id" style="visibility:hidden;width:0px;" />`
    )
    .replace(
      regexpTitle,
      (match, string) =>
        `<span data-title="${string}" class="article-heading-title" style="visibility:hidden;width:0px;" />`
    )
    .replace(regexSquareBrackets, replaceText) // needs to be last
  return renderedHtml
})

onMounted(() => {
  const toggleCallBackModal = document.querySelector('.toggle-callback-modal')
  if (toggleCallBackModal && props.content.includes('MODAL_CALLBACK')) {
    const modalInfoPackTitle = useGetContentPage('modal_form_infopack_title.value')
    const modalInfoPack = useGetContentPage('modal_form_infopack.linkedItems[0]')
    toggleCallBackModal.addEventListener('click', () => {
      globalStore.toggleModal('formModal', { title: modalInfoPackTitle, content: modalInfoPack })
    })
  }
})
</script>

<style lang="scss"></style>
